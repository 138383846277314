<template>
  <v-dialog v-model="dialog" max-width="50%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on">
        <v-icon class="mr-1">mdi-cloud-upload</v-icon>
        Exportar Comissão
      </v-btn>
    </template>
    <ProcessamentoComissao class="mb-3" />
  </v-dialog>
</template>

<script>
import ProcessamentoComissao from "@/components/comissao/processamento/ProcessamentoComissao";
export default {
  components: {
    ProcessamentoComissao
  },

  data() {
    return {
      dialog: false
    };
  }
};
</script>

<style>
.v-dialog {
  background-color: #fff !important;
}
</style>
