<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="info"
      :loading="loading"
      :paginate="true"
      :lastPage="nLastPage"
      @pageChange="fetchResumoMapaGeral(filters, $event)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Comissão</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <ModalProcessamento />
          <FilterExportList
            @selectedFilters="fetchResumoMapaGeral((filters = $event))"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.id_band`]="{ item }">
        <BaseSegmento :segmento="item.id_band" />
      </template>
      <template v-slot:[`item.colaborador`]="{ item }">
        {{ item.id_colaborador }} - {{ item.colaborador | TitleCase }}
      </template>
    </BaseTable>
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable.vue";
import comissao from "@/services/http/comissaoService";
import BaseSegmento from "@/components/shared/BaseSegmento";
import FilterExportList from "./FilterExportList.vue";
import ModalProcessamento from "./ModalProcessamento.vue";
export default {
  components: {
    BaseTable,
    BaseSegmento,
    ModalProcessamento,
    FilterExportList
  },

  data() {
    return {
      loading: false,
      dialog: false,
      search: "",
      labelBtn: "Salvar",
      filters: {},
      nLastPage: 0,
      headers: [
        { text: "Bandeira", value: "id_band" },
        { text: "Empresa", value: "apelido" },
        { text: "Colaborador", value: "colaborador" },
        { text: "Mês", value: "mes_ref" },
        { text: "Ano", value: "ano_ref" },
        { text: "T. Bônus", value: "total_bonus", align: "right" },
        { text: "T. Comissão", value: "total_comissao", align: "right" },
        { text: "Total", value: "total", align: "right" },
        { text: "Situação", value: "situacao", align: "center" },
        { text: "Status", value: "ativo", align: "center" }
      ],
      info: []
    };
  },

  methods: {
    async fetchResumoMapaGeral(filter, nPage = 1) {
      this.loading = true;
      let { data } = await comissao()
        .resumoMapaGeral()
        .show({ page: nPage, ...filter });
      this.nLastPage = data.last_page;
      this.loading = false;
      this.info = data.data;
    }
  },

  mounted() {
    if (this.filters != {}) {
      const date = new Date().toISOString().substr(0, 7);
      const [year, month] = date.split("-");
      const filter = {
        ano_ref: parseInt(year),
        mes_ref: parseInt(month)
      };
      this.fetchResumoMapaGeral(filter);
    }
  }
};
</script>
